import React from 'react';
import {Row, Col, Button} from 'antd';

import Layout from '../components/layout';
import FooterBox from '../components/footerBox';
// import "./style.less";

const PrivacyPolicyPage = () => {
    return (
        <Layout title="Legal Notices">
            <div className="max-width-container">
                <Row>
                    <Col md={{span: 16, offset: 4}}>
                        <div className="DisplayBlackCenter mb40 Title">Privacy Policy</div>
                        <div className="LeadingBlackCenter mb40 Description">TicketTamer cares about your privacy. This privacy policy (“Privacy Policy”) explains the ways in which your information is collected and used in the TicketTamer web sites, and by ReubenLaw LLC, the law firm supporting TicketTamer. Hereinafter, the term TicketTamer refers to both TicketTamer and ReubenLaw LLC. All references to Site expressly refer to the TicketTamer.com web site. All references to Services refer to legal services provided to you by ReubenLaw LLC for the tickets you retain ReubenLaw LLC to handle.</div>
                        <div className="LeadingBlackCenter mb40 Description">By uploading your ticket to a TicketTamer Site, delivering it by any medium to a TicketTamer agent, or otherwise paying for any TicketTamer Service, you are engaging ReubenLaw LLC to represent you on that ticket and agreeing to these Terms of Service and our Privacy Policy. You are also agreeing that TicketTamer will have the right to use your personal and other information as described in this Privacy Policy to effectuate the purposes of our attorney-client relationship.</div>
                        <div className="LeadingBlackCenter mb56 Description">As used in this Privacy Policy, the terms “you” and “your” mean each individual or entity that accesses or uses the Site and/or Services in any manner. If you access the Site and/or use the Services on behalf of an entity, you represent and warrant to TicketTamer that you personally satisfy the eligibility criteria in Paragraph 3 below and you have the authority to bind the business to this Privacy Policy. Your agreement to these terms will be treated as the agreement of the entity you represent</div>
                        <div className="H2BlackLeft mb24">Scope of this Privacy Policy</div>
                        <div className="BodyBlackLeft Description mb40">This Privacy Policy covers personally identifiable and non-personally identifiable information collected by TicketTamer when you visit or use the Site or employ the Services of ReubenLaw LLC. This Privacy Policy does not apply to third parties, including, without limitation, entities that TicketTamer does not own or control, such as third party sites that may have a link to or from the Site.</div>

                        <div className="H2BlackLeft mb24">Your Information is Subject to Attorney-Client Privilege</div>
                        <div className="BodyBlackLeft Description mb40">Merely consulting this Site does not create an attorney-client relationship with ReubenLaw LLC. However, creating an account on the TicketTamer website or uploading, texting, or otherwise delivering your ticket to TicketTamer does create an attorney-client relationship with ReubenLaw LLC for purposes of our representing you on the ticket(s) you have provided. This relationship is confidential and we will consider all information you provide to us to be work product subject to attorney-client privilege. ReubenLaw LLC will not distribute or disclose any information about you or your case unless compelled by a court order. Similarly, by federal law, the server we use is also prohibited from disclosing your information without your consent.</div>

                        <div className="H2BlackLeft mb24">Children under 15 Years of Age</div>
                        <div className="BodyBlackLeft Description mb40">The Site and Services are for general audience services, and are not directed to individuals under 15 years of age. TicketTamer does not knowingly collect personal information from individuals under 15 years of age. If you are under 15 years of age, you may not use the Site or Services.</div>

                        <div className="H2BlackLeft mb24">Personal Information</div>
                        <div className="BodyBlackLeft Description mb40 PreText">By creating an account on the TicketTamer website or uploading, texting, or otherwise delivering your ticket to TicketTamer, you authorize us to collect both personally identifiable information (“PII”) and non-personally identifiable information (“Non-PII”) when you visit or use the Site.</div>

                        <div className="H2BlackLeft mb24">Personally Identifiable Information</div>
                        <div className="BodyBlackLeft Description mb40 PreText">PII means information that would allow a third party to identify or locate a particular person. PII includes, without limitation, your full name, email address, zip code, telephone number, birth date, and credit card number. TicketTamer only collects PII if that information is voluntarily provided by you.</div>

                        <div className="H2BlackLeft mb24">Accounts</div>
                        <div className="BodyBlackLeft Description mb40 PreText">In order to access and use TicketTamer Services, you will be required to create a user account that will provide certain PII, such as your full name, an email address, telephone number, a user name and/or a password, etc. You are under no obligation to provide this information to TicketTamer. However, if you decide not to share this information, you will be unable to create an account on the Site or fully utilize the Services we offer.</div>

                        <div className="H2BlackLeft mb24">Financial Transactions</div>
                        <div className="BodyBlackLeft Description mb40 PreText">TicketTamer uses third parties to provide credit card and other payment processing services, such as billing for the Site and/or Services. This financial information and related payment information is confidential and privileged, and will not be distributed to third parties for any reason without a court order compelling ReubenLaw LLC to do so. TicketTamer and its third party agents may retain the details of financial transactions or payments you make on or through the Site.</div>

                        <div className="H2BlackLeft mb24">Data to Improve Services</div>
                        <div className="BodyBlackLeft Description mb40 PreText">In order to continually improve TicketTamer’s Services, TicketTamer may collect information relevant to those services, such as fines, court costs, and infraction locations.</div>

                        <div className="H2BlackLeft mb24">Non-Personally Identifiable Information</div>
                        <div className="BodyBlackLeft Description mb40 PreText">Non-PII means information that does not allow a third party to identify or locate a particular person. You can visit the Site to read information about the Site and Services offered without revealing any PII. However, during your visit to the Site, TicketTamer may collect anonymous Non-PII such as the activity of your browser when you are viewing the Site; your site path during your visit to the Site; demographic data; the amount of time you spend on the Site and general information regarding your Internet service provider and host.</div>

                        <div className="H2BlackLeft mb24">Logging of Your Activities on the Site</div>
                        <div className="BodyBlackLeft Description mb40 PreText">When you access the Site or Services from a computer, mobile phone or other device, TicketTamer may collect and log information from that device about your browser type, location, and IP address (the number assigned to computers on the Internet), a telephone number from which you contacted TicketTamer, etc. TicketTamer may also collect and log anonymous “traffic data” information that does not personally identify you, but that may be helpful to TicketTamer for marketing purposes or for improving the services that TicketTamer offers to its users. This information may include, without limitation, the pages you visit, the length of your visit, your referring/exit pages, the timing and duration of calls made through the Site and Services, and your clickstream activity and other actions you take while using or visiting the Site.</div>

                        <div className="H2BlackLeft mb24">Cookies</div>
                        <div className="BodyBlackLeft Description mb40 PreText">TicketTamer may use “cookies” to enhance your use of the Site and Services, and to monitor your activity during your visit(s) to the Site. Cookies are text files placed in your computer’s browser to identify your Internet browser and to store your preferences. You can remove or block cookies by programming your Internet browser, but in some cases that may impact your ability to access certain portions of the Site and certain Services.</div>

                        <div className="H2BlackLeft mb24">Beacons</div>
                        <div className="BodyBlackLeft Description mb40 PreText">TicketTamer may also use “web beacons”, clear.gifs, or similar technologies, to track the activity and flow of your visit(s) to the Site. “Web beacons” and clear.gifs are small pieces of code placed on a web page to monitor the behavior of, and to collect data about, the visitors to that page.</div>

                        <div className="H2BlackLeft mb24">TicketTamer’s Use of PII and Non-PII</div>
                        <div className="BodyBlackLeft Description mb40 PreText">TicketTamer may use the PII and Non-PII it collects under this Privacy Policy as follows:</div>

                        <div className="H2BlackLeft mb24">Use of PII</div>
                        <div className="BodyBlackLeft Description mb24 PreText">TicketTamer may use the PII you share with TicketTamer to manage your account, to fulfill your requests for Services, to respond to your inquiries about TicketTamer, to offer you other TicketTamer Services, products, or programs that TicketTamer believes may be of interest to you, to enforce the legal terms that govern your use of the Site and our Services, and for other purposes necessary or appropriate to achieve the purposes for which you provided the information.</div>
                        <div className="BodyBlackLeft Description mb24 PreText">TicketTamer may use the PII you share with TicketTamer to communicate with you, to contact you about your account, to respond to your comments or requests for information, etc. If a comment or request is made by email, text, or direct message, TicketTamer may respond by sending an email, text, or direct message response back to the sender. TicketTamer may store the emails, texts, or direct message conversations received by TicketTamer for archival purposes and, with your permission, for marketing purposes.</div>
                        <div className="BodyBlackLeft Description mb40 PreText">Your PII is encrypted when it is sent to TicketTamer and when it is at rest within TicketTamer. To further protect your PII, TicketTamer uses API Security through JSON Web Tokens.</div>

                        <div className="H2BlackLeft mb24">TicketTamer will not rent, share, or sell the PII you share with us to third parties for marketing purposes.</div>
                        <div className="BodyBlackLeft Description mb24 PreText">TicketTamer may also provide your PII to a third party in those instances where the fulfillment of your request for information, services or products requires TicketTamer to share your PII with a third party, and you have agreed that TicketTamer may provide your PII or access thereto to the third party. For example, you may authorize us to share your PII with a third party to whom you have been referred by TicketTamer for legal services relating to your ticket, such as representation on a personal injury claim arising out of an automobile accident. TicketTamer has no control over the use of your PII by such third parties, and expressly disclaims liability for any prohibited uses in which they may engage.</div>
                        <div className="BodyBlackLeft Description mb24 PreText">TicketTamer may use your email address, phone number, mailing address, or other contact information to send you communications, such as notifications, information about new services offered by TicketTamer, special offers, promotions, etc. If you do not wish to receive communications, you may unsubscribe from such communications by sending an email to Info@TicketTamer.com.</div>
                        <div className="BodyBlackLeft Description mb40 PreText">If you choose to submit content for publication on or through the Site, such as a posting a testimonial, TicketTamer will not post or publish your name or PII in connection with the review unless you choose to include such information in the content of the review itself.</div>

                        <div className="H2BlackLeft mb24">Additionally, TicketTamer may disclose the information it collects on the Site, both PII and Non-PII (collectively “User Information”), under the following circumstances:</div>
                        <div className="BodyBlackLeft Description mb24 PreText">TicketTamer reserves the right to access, read, preserve, and disclose any and all User Information we reasonably believe is necessary to: satisfy any applicable law, regulation, legal process or governmental request; enforce our Terms of Service, including investigation of potential violations hereof; detect, prevent, or otherwise address fraud, security or technical issues; respond to user support requests; protect our rights, property or safety, our users and the public. This includes exchanging information with other companies and organizations for fraud protection and spam/malware prevention.</div>
                        <div className="BodyBlackLeft Description mb24 PreText">If TicketTamer sells all or a part of its business or makes a sale of all or a material part of its assets or is otherwise involved in a merger or transfer of all or a material part of its business, then as part of the applicable transaction, TicketTamer may transfer your User Information to the party or parties involved in the transaction. TicketTamer may also share and/or transfer your User Information to any or all of its affiliated entities (such as any subsidiary or parent).</div>
                        <div className="BodyBlackLeft Description mb40 PreText">TicketTamer may disclose any or all User Information to protect or defend the legal rights or property of TicketTamer or its employees, agents and contractors; to protect the safety of the general public, the users of the Site and the Services and/or the TicketTamer employees, agents, franchises, and contractors; and to investigate, prevent or take action regarding suspected or actual illegal activities or fraud.</div>
                        
                        <div className="H2BlackLeft mb24">Use of Non-PII</div>
                        <div className="BodyBlackLeft Description mb24 PreText">TicketTamer may measure and analyze anonymous statistical Non-PII collected on an aggregated and/or un–aggregated basis in connection with your visit(s) to the Site in order to administer the Site; improve the quality of its Services; improve the design, performance and content of the Site and the Services; and improve your experience on the Site, such as by providing more relevant content to you during your visits.</div>
                        <div className="BodyBlackLeft Description mb24 PreText">TicketTamer may use and/or share anonymous, aggregated and/or un-aggregated Non-PII with third parties for any and all reasonable business purposes, as TicketTamer determines in its sole discretion, including, without limitation, advertising, promotion, business opportunities, etc. TicketTamer may combine (a) your Non-PII with the Non-PII of other users of the Site and (b) Non-PII that TicketTamer collects through the Site with Non-PII TicketTamer may collect from other sources.</div>
                        <div className="BodyBlackLeft Description mb40 PreText">TicketTamer, TicketTamer’s licensors, third party service providers, and advertisers may use Non-PII to customize content, Services, promotions, advertising, etc.; to offer you Services, products, etc.; to manage and measure the performance of advertisements displayed on or delivered by or through the Site; to manage your account and preferences; to compile aggregate data about traffic and interaction on or through the Site so that TicketTamer can improve its user experience; to protect against misuse of the Site and Services; to enforce the TicketTamer Terms of Service and all other laws, rules, or policies that govern your use of the Site and the Services and/or for the purposes for which you provided the information.</div>

                        <div className="H2BlackLeft mb24">Content Posted to Public Areas of the Site</div>
                        <div className="BodyBlackLeft Description mb40 PreText">If you post or provide any information in any public area on the Site, such as a comment or testimonial, or if you otherwise make any information about yourself publicly available on the Site, you should be aware that any publicly disclosed PII can be read, collected, or used by other users of the Site, and could be used to send you unsolicited messages or otherwise. TicketTamer is not in any way responsible for the non-disclosure or protection of any PII or other information you choose to publicly disclose on or through the Site. You are solely responsible for all of the consequences resulting from any such public disclosure.</div>

                        <div className="H2BlackLeft mb24">Third Party Websites</div>
                        <div className="BodyBlackLeft Description mb40 PreText">The Site may contain links to third party websites. By accessing and/or using a linked third party website, you may disclose your personal information to such linked website. Therefore, you should carefully review the privacy policies of these other websites. If you are not comfortable with the policies of a linked website, you should not access or use that website. TicketTamer is not responsible for the policies (privacy or otherwise) or the content, services or products offered by or through any third party website. It is solely your responsibility to review the privacy policy and other policies of third party websites to confirm that you understand and agree with their policies.</div>

                        <div className="H2BlackLeft mb24">Data Security</div>
                        <div className="BodyBlackLeft Description mb40 PreText">The User Information we collect is stored on a secure, password-protected server and only authorized personnel have access to your information. We use commercially reasonable encryption technologies with respect to the receipt and transfer of User Information you submit to us via the Site and Services, including using SSL technology for certain sensitive information. Nevertheless, despite our best efforts, no transmission over the Internet and no data storage method can be guaranteed to be safe 100% of the time. TicketTamer does not guarantee that its security measures will not be circumvented or breached or that your User Information will not be accessed by, used by, modified by or disclosed to a third party. TicketTamer is not responsible for any such circumvention or breach by any third party. Additionally, it is solely your responsibility, and not the responsibility of TicketTamer, to protect the confidentiality of your password and login credentials.</div>

                        <div className="H2BlackLeft mb24">Updating Your Information and Deleting Your Account</div>
                        <div className="BodyBlackLeft Description mb40 PreText">TicketTamer may from time to time amend this Privacy Policy to reflect changes in TicketTamer’s practices and services, as well as applicable laws. If TicketTamer makes any material changes to the way TicketTamer collects, uses, or shares your User Information, TicketTamer will notify you of those changes via a posting on the Site or an electronic communication to you that will also indicate the effective date of the posted changes. Your continued use of the Site and the Services following the effective date of a change to this Privacy Policy signifies your acceptance of and agreement to the terms of the revised Privacy Policy. TicketTamer encourages you to regularly review this Privacy Policy so that you remain updated as to any changes. TicketTamer will indicate that changes have been made hereto by providing a “Last Updated” date at the top of this Privacy Policy following the effective date, as appropriate.</div>

                        <div className="H2BlackLeft mb24">Contact Us</div>
                        <div className="BodyBlackLeft Description mb40 PreText">If you have any questions about this Privacy Policy or to report any privacy concerns, please email us at Info@TicketTamer.com, call us at 314-728-4444 (St. Louis), 636-439-4444 (St. Charles), 573-466-6644 (Columbia), 816-617-6644 (Kansas City), 417-691-6644 (Springfield), or 660-233-6644 (North/Western Missouri), or by mail at P.O. Box 39023, St. Louis, Mo., 63109.</div>

                        <div className="LeadingBlackCenter mb40 PreText">{`The choice of a lawyer is an important decision and should not be based solely on
advertisements. TicketTamer is a public interest program of ReubenLaw LLC, Richard Reuben,
managing partner.`}</div>

                    </Col>
                </Row>
            </div>
            <FooterBox />
        </Layout>
    );
}

export default PrivacyPolicyPage;
